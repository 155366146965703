// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { HasId } from '../core/entity/entity.service';
import { MitigationIndicator } from '../mitigation-indicator/mitigation-indicator.model';
import { QuestionnaireSection } from '../questionnaire-section/questionnaire-section.model';

export interface Question extends HasId {
  text: string;
  type: keyof typeof QuestionType;
  index: number;
  questionnaireSection: Partial<QuestionnaireSection>;
  // TODO: Should change type to `mitigationIndicator: MitigationIndicator | null;` The property should always exist.
  mitigationIndicator?: MitigationIndicator;
}

export interface QuestionBreakdown extends Question {
  questionnaireSection: QuestionnaireSection;
  multiple: boolean;
  options: QuestionSelectOptionBreakdown[];
}

export type SubQuestion = Omit<Question, 'questionnaireSection'>;

export interface SubQuestionBreakdown extends SubQuestion {
  multiple: boolean;
  options: QuestionSelectOptionBreakdown[];
}

export interface QuestionSelect extends Question {
  multiple: boolean;
  options: QuestionSelectOption[];
}

export type SubQuestionSelect = Omit<QuestionSelect, 'questionnaireSection'>;

export interface SubQuestionSelectBreakdown extends SubQuestionSelect {
  countOption: number;
}

export interface QuestionSelectOption extends HasId {
  text: string;
  index: number;
  score: number;
  subQuestion?: SubQuestion | SubQuestionSelect;
}

export interface QuestionSelectOptionBreakdown extends QuestionSelectOption {
  subQuestion?: SubQuestionBreakdown;
  countOption: number;
  percentage: number;
  subQuestionVisible?: boolean;
}

/**
 * Available question types. For UI display, show the value. When storing into database, store the key.
 */
export enum QuestionType {
  TEXT = 'Text',
  SELECT = 'Select',
}
