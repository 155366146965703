// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EntityService } from '../core/entity/entity.service';
import { AlertService } from '../shared/alert.service';
import { Question, QuestionBreakdown, QuestionSelect } from './question.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionService extends EntityService<Question | QuestionSelect | QuestionBreakdown> {
  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getBreakdown$(questionnaireId: number): Observable<QuestionBreakdown[] | Question[]> {
    const req$ = super.getAll$({ questionnaire_id: questionnaireId });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} by questionnaire (${questionnaireId}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    });
  }

  getAllByQuestionnaireSection$(id: number): Observable<Question[]> {
    const req$ = super.getAll$({ questionnaire_section_id: id.toString() });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} by questionnaire section(${id}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    });
  }

  getAllByQuestionnaireSections$(ids: number[]): Observable<Question[]> {
    // backend django-filter expects multivalue param as comma-separated string
    const params = ids.length ? { questionnaire_section_ids: ids.join() } : {};
    const req$ = super.getAll$(params);

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} by questionnaire sections(${ids}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    });
  }

  pluralIdentifier(): string {
    return 'questions';
  }

  singularIdentifier(): string {
    return 'question';
  }
}
